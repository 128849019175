import axios from '../../util/axios';
import router from '../../router';
import { setAddRouter } from '../../router';
import api from '../../util/api';
import { Message, MessageBox } from 'element-ui';
import { resetVuex } from '@/util/util';
import store from '../index';
import cookies from 'vue-cookies';
// 初始值 用于清空数据
const default_state = {
    menuLeft: [],
    userInfo: {},
    shopInfo: {},
    relationMenu: [],
    loadingFlag: false,
};
const state = {
    menuLeft: [],
    userInfo: {},
    shopInfo: {},
    endTime: 0,
    relationMenu: [],
    loadingFlag: false,
};
const mutations = {
    resetState (state) {
        let data = Object.assign({}, default_state);
        for (let i in data) {
            state[i] = data[i];
        }
    },
    setRoutes (state, data) {
        state.menuLeft = data;
    },
    setRelationMenu (state, data) {
        state.relationMenu = data;
    },
    getUserInfo (state, data) {
        state.userInfo = data || {};
    },
    getEndTime (state, data) {
        state.endTime = data || 0;
    },
    getShopInfo (state, data) {
        state.shopInfo = data || {};
    },
    changeLoadingFlag (state, data) {
        state.loadingFlag = data;
    },
};

const actions = {
    outLogin ({ commit }, data = {}) {
        MessageBox.confirm('确认退出登录吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            axios.post(api.user.logout).then(res => {
                if (res.code == 0) {
                    if (res.code == 0) {
                        Message({
                            message: '退出登录成功',
                            type: 'success',
                        });
                        resetVuex();
                        cookies.remove('token');
                        router.push('/login');
                        localStorage.removeItem('u_id');
                        if (data.success) data.success();
                    }
                } else {
                    Message.error(res.msg);
                }
            });
        });
    },
    //返回站点处理
    backProgram ({ commit }, data = {}) {
        axios.post(api.user.backProgram, data).then(res => {
            if (res.code == 0) {
                resetVuex(['utils', 'user']);
                router.push('/shop');
            } else {
                Message.error(res.msg);
            }
        });
    },
    //进入站点处理
    enterProgram ({ dispatch }, e = {}) {
        let now_time = parseInt(new Date().getTime() / 1000);
        if (e.endtime - now_time > 0) {
            axios
                .post(api.site.innerProgram, {
                    uniacid: e.uniacid,
                })
                .then(res => {
                    if (res.code == 0) {
                        localStorage.setItem('case_id', e.case_id.id);
                        localStorage.setItem('u_id', e.uniacid);
                        localStorage.setItem('uniac_endtime', e.endtime);
                        localStorage.setItem('uniac_startTime', e.create_time);
                        localStorage.setItem('store_type', e.meal.name);
                        dispatch('get_menu', {
                            success () {
                                setAddRouter();
                                let menuLeft = store.getters.menuLeft;
                                if (e.is_site) {
                                    if (router.app._route.path == '/index') {
                                        window.onload();
                                    } else {
                                        router.push(menuLeft[0].redirect);
                                    }
                                } else {
                                    router.push(menuLeft[0].redirect);
                                }
                            },
                        });
                    } else {
                        Message.error(res.msg || '');
                    }
                });
        } else {
            MessageBox.confirm('站点已过期是否要去续费？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                router.push();
                router.push({
                    path: '/shop/shopRenew',
                    query: {
                        id: e.uniacid,
                    },
                });
            });
        }
    },
    getUserInfo ({ commit }) {
        axios.post(api.user.userInfo).then(res => {
            if (res.code == 0) {
                commit('getUserInfo', res.result.info);
            } else {
                Message.error(res.msg);
            }
        });
    },
    getShopInfo ({ commit }) {
        axios.post(api.user.shopInfo).then(res => {
            if (res.code == 0) {
                console.log(res.result.expire_time);
                commit('getEndTime', res.result.expire_time);
                commit('getShopInfo', res.result.info);
            } else {
                Message.error(res.msg);
            }
        });
    },
    get_menu ({ commit }, data = {}) {
        axios
            .post(api.auths.get_menu, {
                case_id: localStorage.getItem('case_id'),
            })
            .then(res => {
                if (res.code == 0) {
                    let arr = [];
                    let list = res.result;
                    for (let i in list) {
                        let children = list[i].childs || [];
                        let obj = {
                            path: list[i].auth_key,
                            meta: {
                                icon: list[i].auth_icon,
                                title: list[i].auth_meta,
                                name: list[i].auth_name,
                                rules: 1,
                            },
                            children: [],
                        };
                        for (let y in children) {
                            obj.children.push({
                                path: children[y].auth_key,
                                name: children[y].auth_name,
                                meta: {
                                    rules: 1,
                                    name: children[y].auth_name,
                                },
                            });
                        }

                        arr.push(obj);
                    }
                    commit('setRoutes', arr);
                    if (arr.length) data.success(arr);
                } else {
                    Message.error(res.msg);
                }
            });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
